<template>
  <fieldset class="mb-4">
    <div class="flex flex-col">
      <label class="font-bold">Unternehmenssuche</label>
      <small> Bitte geben Sie den Namen des Unternehmens ein, das Sie hinzufügen möchten.</small>
    </div>
    <Combobox v-model="selectedCompany">
      <ComboboxInput
        :display-value="(companyResult: unknown) => (companyResult ? (companyResult as CompanySearchResult).name : '')"
        class="w-full mb-2"
        @change="search($event.target.value)"
      />
      <ComboboxOptions>
        <ComboboxOption v-for="companyResult in companyResults" :key="companyResult.id" v-slot="{ active }" :value="companyResult">
          <div class="border-b border-slate-700" :class="{ 'bg-slate-400': active }">
            <strong>{{ companyResult.name }}</strong>
            <br />
            {{ companyResult.location.street }} {{ companyResult.location.house }}<br />
            {{ companyResult.location.zip }} {{ companyResult.location.city }}
          </div>
        </ComboboxOption>
      </ComboboxOptions>
    </Combobox>
  </fieldset>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption } from '@headlessui/vue'
import { debounce } from 'lodash'
import { search as searchCompany } from '../services/companyService'
import type { CompanySearchResult } from '../types'
import type { Company } from '../stores/answers'

const emit = defineEmits<{
  (e: 'addCompany', company: Company): void
}>()

const nullCompany = {
  id: '',
  id_type: '',
  name: '',
  location: {
    street: '',
    house: '',
    zip: '',
    city: '',
    country: {
      code: '',
      text: '',
    },
  },
}

const selectedCompany = ref<CompanySearchResult>(nullCompany)

const companyResults = ref<CompanySearchResult[]>([])
const showCompanyList = ref(false)

let resetSelectedCompany = false

watch(selectedCompany, (company) => {
  if (!resetSelectedCompany) {
    emit('addCompany', searchResultToCompany(company))
    resetSelectedCompany = true
    selectedCompany.value = nullCompany
  } else {
    resetSelectedCompany = false
  }
})

const search = debounce(
  async (name: string) => {
    const companies = await searchCompany(name).catch(() => [])
    companyResults.value = companies.filter((company) => company.name.length > 0)
    showCompanyList.value = true
  },
  300,
  { maxWait: 800 }
)

const searchResultToCompany = (company: CompanySearchResult) => ({
  companyName: company.name,
  address: `${company.location.street || ''} ${company.location.house || ''}`,
  city: company.location.city,
  zip: company.location.zip,
  country: company.location.country.text,
  onrId: company.id,
  onrIdType: company.id_type,
  registryNumber: '',
  registry: '',
})
</script>
