<template>
  <h1 class="mt-4 mb-4 lg:mb-6 text-sans text-base md:text-xl lg:text-4xl break-words">Transparenzregister-Service by YSolutions</h1>

  <h3 class="mt-4">Wählen Sie Ihre Gesellschaftsform</h3>

  <div class="mt-4">
    <ul>
      <li>
        <label id="gmbh" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="flowId" class="mr-4" type="radio" value="gmbh" autocomplete="off" />
          GmbH
        </label>
      </li>

      <li>
        <label id="ohg" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="flowId" class="mr-4" type="radio" value="publicgmbh" autocomplete="off" />
          GmbH/UG (öffentliches Unternehmen)
        </label>
      </li>

      <li>
        <label id="ug" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="flowId" class="mr-4" type="radio" value="ug" autocomplete="off" />
          UG
        </label>
      </li>

      <li>
        <label id="kg" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="flowId" class="mr-4" type="radio" value="kg" autocomplete="off" />
          Kommanditgesellschaft (KG)
        </label>
      </li>

      <li>
        <label id="gmbhcokg" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="flowId" class="mr-4" type="radio" value="gmbhcokg" autocomplete="off" />
          GmbH & Co. KG
        </label>
      </li>

      <li>
        <label id="ag" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="flowId" class="mr-4" type="radio" value="ag" autocomplete="off" />
          Aktiengesellschaft (AG)
        </label>
      </li>

      <li>
        <label id="ohg" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="flowId" class="mr-4" type="radio" value="publicag" autocomplete="off" />
          Aktiengesellschaft (AG - öffentliches Unternehmen)
        </label>
      </li>

      <li>
        <label id="ohg" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="flowId" class="mr-4" type="radio" value="ohg" autocomplete="off" />
          OHG
        </label>
      </li>
    </ul>
  </div>

  <button class="mt-4 btn" :disabled="!flowId" @click.stop="router.push({ name: 'questionnaire', params: { flowId } })">Weiter</button>
</template>

<script setup lang="ts">
import { ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const flowId: Ref<string | null> = ref(null)
</script>
