import type { OfferRow, SubscriptionTypeMap } from '@/types'

const subscriptionTypeTrans = {
  totalSumFirstYear: 'Einmalige Kosten im 1. Jahr',
  totalSum: 'Einmalige Kosten',
} as SubscriptionTypeMap

const getSubscriptionTans = (subscriptionType: keyof SubscriptionTypeMap) =>
  subscriptionTypeTrans[`${subscriptionType}`] || 'Unknown subscription model'
export const commonOfferData = [
  {
    headline: (Postfix = '') => getSubscriptionTans(`totalSum${Postfix}` as keyof SubscriptionTypeMap),
    lineItem: false,
    priceTypeCode: 'totalSum',
    priceType: 'Festpreis',
    highlight: true,
    sumPostFixText: 'zzgl. 100,00 pro Meldung falls erfoderlich',
  },
  {
    headline: 'Kosten ab dem 2. Jahr',
    priceTypeCode: 'yearlySum',
    priceType: 'Festpreis pro Jahr',
    highlight: true,
    sumPostFixText: 'zzgl. 100,00 pro Meldung falls erfoderlich',
  },
] as OfferRow[]

export const simpleOffer = [
  {
    id: 1,
    code: 'offer_1_entry',
    bullets: [
      'Datenbankgestützte Analyse der Beteiligungsstruktur und Ermittlung der wirtschaftlich Berechtigten (eine Beteiligungsstufe)',
      'Zeitersparnis durch automatisierte Vorbelegung der relevanten Daten der wirtschaftlich Berechtigten für das Transparenzregister',
      'Vorlage der ermittelten Daten durch YSolutions an den Kunden',
      [
        'Komplettierung der nicht automatisiert abrufbaren Daten durch den Kunden (insb. Staatsangehörigkeiten der wirtschaftlich Berechtigten) und Freigabe durch den Kunden',
      ],
      'Endkontrolle der erfassten Daten durch YSolutions-Mitarbeiter durch Vergleich der erfassten Daten mit der aktuellen Gesellschafterliste/dem Musterprotokoll im Handelsregister',
      'Durchführung der Meldung ans Transparenzregister durch YSolutions-Mitarbeiter (z.Zt. bietet das Transparenzregister keinen automatisierten Upload der Meldungen an, die Meldung muss „per Hand“ eingereicht werden)',
    ],
    headline: 'Analyse, Ermittlung, Kontrolle und Meldung',
    lineItem: true,
    selected: true,
    disabled: true,
    priceCents: 8900,
    priceTypeCode: 'fixed',
    priceType: 'Festpreis',
    highlight: false,
  },
  {
    id: 2,
    code: 'offer_1_entry_monitoring',
    bullets: [
      'Eigenes Kundenportal für jede meldepflichtige Rechtseinheit',
      'Permanente Verfügbarkeit der gemeldeten Daten',
      'Permanente Verfügbarkeit der Eingangsmitteilung (die Eingangsmitteilung des Transparenzregisters ist das Referenzdokument für künftige Änderungen)',
      'Statusmeldung über den erfolgten Eintrag',
      'Möglichkeit des Uploads der Korrespondenz mit Anteilseignern/wirtschaftlich Berechtigten zur Erfüllung der Nachweispflicht nach § 20 Abs. 3a GWG.',
      ['Upload erfolgt automatisch durch Weiterleiten der Email-Korrespondenz an info@ysolutions.com'],
      'Vierteljährliche, automatisierte Wiedervorlage an die Kontakt-Emailadresse zur Überprüfung der Aktualität der Einträge (insbesondere relevant für Änderungen in der Person der wirtschaftlichen Berechtigten oder von deren Wohnorten, des prozentualen Umfangs der Beteiligung und der Art der Kontrolle)',
      'Einfache Möglichkeit der Mitteilung von Änderungen zur Erfüllung der Aktualisierungspflicht nach § 20 Abs. 1 GWG und zur Vermeidung von Unstimmigkeitsmeldungen nach § 23a GWG',
      'Permanentes, datenbankgestütztes Monitoring der Aktualität der Eintragung, proaktive Warnmeldung an die hinterlegte Kontakt-Emailadresse im Falle einer Diskrepanz zwischen Datenbank und Eintragung',
      [
        'kein Ersatz der Pflicht des Kunden, Änderungen unverzüglich gegenüber YSolutions anzuzeigen, da die Datenbank nicht vollständig und nicht tagesaktuell sein kann',
      ],
    ],
    headline: 'Kundenportal und Monitoring',
    lineItem: true,
    selected: true,
    disabled: false,
    priceCents: 35000,
    priceTypeCode: 'yearly',
    priceType: 'pro Jahr',
    highlight: false,
  },
  ...commonOfferData,
] as OfferRow[]

export const individualOffer = [
  {
    id: 1,
    code: 'individual_offer',
    bullets: [
      'Individuelle Analyse der Beteiligungs- und Organisationsstruktur der zu meldenden Rechtseinheit',
      'Ermittlung der wirtschaftlich Berechtigten durch YSolutions-Experten aufgrund der öffentlich recherchierbaren Daten sowie weiterer ggf. vom Kunden zur Verfügung gestellter Informationen',
      'Vorbereitung der Kommunikation mit Anteilseignern bzw. möglichen wirtschaftlich Berechtigten, falls zur Ermittlung erforderlich',
      'Vorlage der ermittelten Daten durch YSolutions an den Kunden',
      ['Freigabe der ermittelten Daten durch den Kunden'],
    ],
    lineItem: true,
    selected: true,
    disabled: true,
    headline: 'Analyse und Ermittlung',
    priceCents: 50000,
    priceTypeCode: 'fixed',
    priceType: 'Festpreis',
    highlight: false,
  },
  {
    id: 2,
    bullets: [
      'Durchführung der Meldung an das Transparenzregister durch YSolutions-Mitarbeiter (z.Zt. bietet das Transparenzregister keinen automatisierten Upload der Meldungen an, die Meldung muss „per Hand“ eingereicht werden)',
    ],
    code: 'individual_offer_entry',
    lineItem: true,
    selected: true,
    disabled: true,
    headline: 'Meldung an das Transparenzregister',
    priceCents: 10000,
    priceTypeCode: 'fixed',
    priceType: 'Festpreis',
    highlight: false,
    hideItemCountMultiplier: true,
    alternativeToMultiplier: 'à',
  },
  {
    id: 3,
    bullets: [
      'Eigenes Kundenportal für jede meldepflichtige Rechtseinheit',
      'Permanente Verfügbarkeit der gemeldeten Daten',
      'Permanente Verfügbarkeit der Eingangsmitteilung (die Eingangsmitteilung des Transparenzregisters ist das Referenzdokument für künftige Änderungen)',
      'Statusmeldung über den erfolgten Eintrag',
      'Möglichkeit des Uploads der Korrespondenz mit Anteilseignern/wirtschaftlich Berechtigten zur Erfüllung der Nachweispflicht nach § 20 Abs. 3a GWG.',
      ['Upload erfolgt automatisch durch Weiterleiten der Email-Korrespondenz an info@ysolutions.com'],
      'Vierteljährliche, automatisierte Wiedervorlage an die Kontakt-Emailadresse zur Überprüfung der Aktualität der Einträge (insbesondere relevant für Änderungen in der Person der wirtschaftlichen Berechtigten oder von deren Wohnorten, des prozentualen Umfangs der Beteiligung und der Art der Kontrolle)',
      'Einfache Möglichkeit der Mitteilung von Änderungen zur Erfüllung der Aktualisierungspflicht nach § 20 Abs. 1 GWG und zur Vermeidung von Unstimmigkeitsmeldungen nach § 23a GWG',
      'Permanentes, datenbankgestütztes Monitoring der Aktualität der Eintragung, proaktive Warnmeldung an die hinterlegte Kontakt-Emailadresse im Falle einer Diskrepanz zwischen Datenbank und Eintragung',
      [
        'kein Ersatz der Pflicht des Kunden, Änderungen unverzüglich gegenüber YSolutions anzuzeigen, da die Datenbank nicht vollständig und nicht tagesaktuell sein kann',
      ],
    ],
    code: 'individual_offer_monitoring',
    lineItem: true,
    selected: true,
    disabled: false,
    headline: 'Kundenportal und Monitoring',
    priceCents: 35000,
    priceTypeCode: 'yearly',
    priceType: 'preis pro Jahr',
    highlight: false,
  },
  ...commonOfferData,
] as OfferRow[]

export const complexOffer = [
  {
    id: 1,
    code: 'offer_2_entry',
    lineItem: true,
    selected: true,
    disabled: true,
    bullets: [
      'Datenbankgestützte Analyse der Beteiligungsstruktur und Ermittlung der wirtschaftlich Berechtigten (eine Beteiligungsstufe)',
      'Zeitersparnis durch automatisierte Vorbelegung der relevanten Daten der wirtschaftlich Berechtigten für das Transparenzregister',
      'Vorlage der ermittelten Daten durch YSolutions an den Kunden',
      [
        'Komplettierung der nicht automatisiert abrufbaren Daten durch den Kunden (insb. Staatsangehörigkeiten der wirtschaftlich Berechtigten) und Freigabe durch den Kunden',
      ],
      'Endkontrolle der erfassten Daten durch YSolutions-Mitarbeiter durch Vergleich der erfassten Daten mit den aktuellen Handelsregisterauszügen bzw. Gesellschafterlisten/den Musterprotokollen im Handelsregister aller beteiligten Gesellschaften (zwei Beteiligungsebenen)',
      'Durchführung der Meldung ans Transparenzregister durch YSolutions-Mitarbeiter (z.Zt. bietet das Transparenzregister keinen automatisierten Upload der Meldungen an, die Meldung muss „per Hand“ eingereicht werden)',
    ],
    headline: 'Analyse, Ermittlung, Kontrolle und Meldung',
    priceCents: 18900,
    priceTypeCode: 'fixed',
    priceType: 'Festpreis',
    highlight: false,
  },
  {
    id: 2,
    code: 'offer_2_entry_monitoring',
    lineItem: true,
    selected: false,
    bullets: [
      'Eigenes Kundenportal für jede meldepflichtige Rechtseinheit',
      'Permanente Verfügbarkeit der gemeldeten Daten',
      'Permanente Verfügbarkeit der Eingangsmitteilung (die Eingangsmitteilung des Transparenzregisters ist das Referenzdokument für künftige Änderungen)',
      'Statusmeldung über den erfolgten Eintrag',
      'Möglichkeit des Uploads der Korrespondenz mit Anteilseignern/wirtschaftlich Berechtigten zur Erfüllung der Nachweispflicht nach § 20 Abs. 3a GWG.',
      ['Upload erfolgt automatisch durch Weiterleiten der Email-Korrespondenz an info@ysolutions.com'],
      'Vierteljährliche, automatisierte Wiedervorlage an die Kontakt-Emailadresse zur Überprüfung der Aktualität der Einträge (insbesondere relevant für Änderungen in der Person der wirtschaftlichen Berechtigten oder von deren Wohnorten, des prozentualen Umfangs der Beteiligung und der Art der Kontrolle)',
      'Einfache Möglichkeit der Mitteilung von Änderungen zur Erfüllung der Aktualisierungspflicht nach § 20 Abs. 1 GWG und zur Vermeidung von Unstimmigkeitsmeldungen nach § 23a GWG',
      'Permanentes, datenbankgestütztes Monitoring der Aktualität der Eintragung, proaktive Warnmeldung an die hinterlegte Kontakt-Emailadresse im Falle einer Diskrepanz zwischen Datenbank und Eintragung',
      [
        'kein Ersatz der Pflicht des Kunden, Änderungen unverzüglich gegenüber YSolutions anzuzeigen, da die Datenbank nicht vollständig und nicht tagesaktuell sein kann',
      ],
    ],
    headline: 'Kundenportal und Monitoring',
    priceCents: 35000,
    priceTypeCode: 'yearly',
    priceType: 'pro Jahr',
    highlight: false,
  },
  ...commonOfferData,
] as OfferRow[]

export const inconsistencyReportOffer = [
  {
    id: 1,
    code: 'inconcistency_report_offer',
    bullets: [
      'Ausräumen von bestehenden Unstimmigkeitsmeldungen und Korrespondenz mit der Registerbehörde',
      'Individuelle Analyse der Beteiligungs- und Organisationsstruktur der zu meldenden Rechtseinheit',
      'Ermittlung der wirtschaftlich Berechtigten durch YSolutions-Experten aufgrund der öffentlich recherchierbaren Daten sowie weiterer ggf. vom Kunden zur Verfügung gestellter Informationen',
      'Vorbereitung der Kommunikation mit Anteilseignern bzw. möglichen wirtschaftlich Berechtigten, falls zur Ermittlung erforderlich',
      'Vorlage der ermittelten Daten durch YSolutions an den Kunden',
      ['Freigabe der ermittelten Daten durch den Kunden'],
    ],
    lineItem: true,
    selected: true,
    disabled: true,
    headline: 'Ausräumen von Unstimmigkeitsmeldungen und Clean-Up',
    priceCents: 95000,
    priceTypeCode: 'fixed',
    priceType: 'Festpreis',
    highlight: false,
  },
  {
    id: 2,
    bullets: [
      'Durchführung der Meldung an das Transparenzregister durch YSolutions-Mitarbeiter (z.Zt. bietet das Transparenzregister keinen automatisierten Upload der Meldungen an, die Meldung muss „per Hand“ eingereicht werden)',
    ],
    code: 'inconsistency_report_offer_entry',
    lineItem: true,
    selected: true,
    disabled: true,
    headline: 'Meldung an das Transparenzregister',
    priceCents: 10000,
    priceTypeCode: 'fixed',
    priceType: 'Festpreis',
    highlight: false,
    hideItemCountMultiplier: true,
    alternativeToMultiplier: 'à',
  },
  {
    id: 3,
    bullets: [
      'Eigenes Kundenportal für jede meldepflichtige Rechtseinheit',
      'Permanente Verfügbarkeit der gemeldeten Daten',
      'Permanente Verfügbarkeit der Eingangsmitteilung (die Eingangsmitteilung des Transparenzregisters ist das Referenzdokument für künftige Änderungen)',
      'Statusmeldung über den erfolgten Eintrag',
      'Möglichkeit des Uploads der Korrespondenz mit Anteilseignern/wirtschaftlich Berechtigten zur Erfüllung der Nachweispflicht nach § 20 Abs. 3a GWG.',
      ['Upload erfolgt automatisch durch Weiterleiten der Email-Korrespondenz an info@ysolutions.com'],
      'Vierteljährliche, automatisierte Wiedervorlage an die Kontakt-Emailadresse zur Überprüfung der Aktualität der Einträge (insbesondere relevant für Änderungen in der Person der wirtschaftlichen Berechtigten oder von deren Wohnorten, des prozentualen Umfangs der Beteiligung und der Art der Kontrolle)',
      'Einfache Möglichkeit der Mitteilung von Änderungen zur Erfüllung der Aktualisierungspflicht nach § 20 Abs. 1 GWG und zur Vermeidung von Unstimmigkeitsmeldungen nach § 23a GWG',
      'Permanentes, datenbankgestütztes Monitoring der Aktualität der Eintragung, proaktive Warnmeldung an die hinterlegte Kontakt-Emailadresse im Falle einer Diskrepanz zwischen Datenbank und Eintragung',
      [
        'kein Ersatz der Pflicht des Kunden, Änderungen unverzüglich gegenüber YSolutions anzuzeigen, da die Datenbank nicht vollständig und nicht tagesaktuell sein kann',
      ],
    ],
    code: 'inconsistency_report_offer_monitoring',
    lineItem: true,
    selected: false,
    headline: 'Kundenportal und Monitoring',
    priceCents: 35000,
    priceTypeCode: 'yearly',
    priceType: 'preis pro Jahr',
    highlight: false,
  },
  ...commonOfferData,
] as OfferRow[]
